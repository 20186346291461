import React from 'react';
import PropTypes from 'prop-types';

import pathOr from 'ramda/src/pathOr';

import Button from '@jetbrains/ring-ui/components/button/button';
import Island, {Header, Content} from '@jetbrains/ring-ui/components/island/island';

import OrderEditForm from '../forms/order-edit';

import {
  getLineItemMetaData,
  getDeliveryTimeframe,
  getLineItemNames
} from '../../lib/woocommerce';

function Order(props) {
  const size = getLineItemMetaData(props.order.line_items, 'pa_sizes', '');
  const products = getLineItemNames(props.order, size);
  const city = pathOr('N/A', ['shipping', 'city'], props.order);
  const timeframe = getDeliveryTimeframe(props.order);

  function handleExportClick() {
    props.yelloExport(props.order.id, {state: 'new'});
  }

  return (
    <div style={{margin: '0px 5px 50px 5px'}}>
      <Button
        primary
        onClick={handleExportClick}
        style={{marginBottom: '5px'}}
      >
        {'Export'}
      </Button>
      <Island>
        <Header border>
          {`#${props.order.id} | ${products} | ${city} ${timeframe}`}
        </Header>
        <Content>
          <OrderEditForm
            fields={[
              'recipient_company',
              'recipient_address_1',
              'recipient_address_2',
              'recipient_city',
              'recipient_state',
              'recipient_postcode',
              'order_status'
            ]}
            order={props.order}
            onEdit={props.onEdit}
          />
        </Content>
      </Island>
    </div>
  );
}

Order.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.number.isRequired,
    // eslint-disable-next-line camelcase
    line_items: PropTypes.array
  }),
  onEdit: PropTypes.func.isRequired,
  yelloExport: PropTypes.func.isRequired
};

export default Order;
