import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {debounce} from 'lodash';

import Input, {Size, Theme} from '@jetbrains/ring-ui/components/input/input';

const delay = 250;
const doSearch = debounce(dispatchSearchRequest, delay);

function dispatchSearchRequest(dispatch, value) {
  dispatch(value);
}

function Search(props) {
  const [input, setInput] = useState('');

  useEffect(() => {
    doSearch(props.dispatch, input);
  }, [input]);

  function handleInput(e) {
    setInput(e.target.value);
  }

  function clear() {
    setInput('');
    props.onClear(null);
  }

  return (
    <Input
      label={props.label}
      value={input}
      onChange={handleInput}
      onClear={clear}
    />
  );
}

Search.propTypes = {
  label: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired
};

export default Search;
