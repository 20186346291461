exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".header__root___3vw4j {\n  position: fixed;\n  left: 0;\n  right: 0;\n}\n.header__logo___2_vVz {\n  display: flex;\n  align-items: center;\n  padding-right: 50px;\n}\n\n.header__logo___2_vVz:focus-visible {\n  outline: none;\n}\n\n.header__nested___1kXkQ {\n  color: #333 !important;\n  text-decoration: none;\n}\n\n.header__nested___1kXkQ:focus-visible {\n  font-weight: bold;\n  outline: none;\n}\n\n.header__link___j_mnW {\n  font-size: 1rem;\n  color: #fafafa !important;\n  text-decoration: none;\n}\n\n.header__link___j_mnW:focus-visible {\n  outline: none;\n}\n\n.header__active___1tLNi {\n  border-bottom: 4px solid rgb(255,0, 140);\n}\n\n.header__dropdown___EhIVV:last-child {\n  text-align: center;\n  padding-right: 13px !important\n}\n\n.header__nested___1kXkQ {\n  color: #333 !important;\n  display: block;\n  padding: 7px 20px;\n}\n\n.header__nested___1kXkQ:first-child {\n  padding-top: 20px;\n}\n\n.header__nested___1kXkQ:last-child {\n  padding-bottom: 20px;\n}", ""]);

// exports
exports.locals = {
	"root": "header__root___3vw4j",
	"logo": "header__logo___2_vVz",
	"nested": "header__nested___1kXkQ",
	"link": "header__link___j_mnW",
	"active": "header__active___1tLNi",
	"dropdown": "header__dropdown___EhIVV"
};