import React from 'react' 
import Header, { Logo } from '@jetbrains/ring-ui/components/header/header'
import { Link, withRouter } from 'react-router-dom'
import classnames from 'classnames'

import fbLogo  from '../../assets/logo.png'
import styles from './header.css'

const R = require('ramda')

const MyHeader = (props) => {
  const isActive = (pathname) => props.location.pathname === pathname

  return (
    <Header className={styles.root}>
      <Link to='/' className={styles.logo}>
        <img src={fbLogo}/>
      </Link>
      <Link
        psuedo='true'
        to='/couriers'
        className={classnames(
          styles.link,
          { [styles.active]: isActive('/couriers') }
        )}
      >
        Couriers
      </Link>
      <Link
        psuedo='true'
        to='/sales'
        className={classnames(
          styles.link,
          { [styles.active]: isActive('/sales') }
        )}
      >
        Sales Report
      </Link>
      <Link
        psuedo='true'
        to='/labels'
        className={classnames(
          styles.link,
          { [styles.active]: isActive('/labels') }
        )}
      >
        Print Labels
      </Link>
      <Link
        psuedo='true'
        to='/products'
        className={classnames(
          styles.link,
          { [styles.active]: isActive('/products') }
        )}
      >
        Products
      </Link>
    </Header>
  )
}

export default withRouter(MyHeader)
