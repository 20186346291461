exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".label-list__container___tSReM {\n  margin: 30px 0;\n}", ""]);

// exports
exports.locals = {
	"container": "label-list__container___tSReM"
};