import jsPDF from 'jspdf'
import moment from 'moment'

import caveat from './fonts/caveat'
import neuzeit from './fonts/neuzeit-grotesk'
import lust from './fonts/lust'

const R = require('ramda')

caveat(jsPDF)
neuzeit(jsPDF)
lust(jsPDF)

export function print (labels) {
  const PAGE_WIDTH = 340.15748
  const PAGE_HEIGHT = 143.622047
  const LINE_WIDTH = 240

  const doc = new jsPDF({
    orientation: 'landscape',
    unit: 'px',
    format: [PAGE_HEIGHT, PAGE_WIDTH]
  })

  function calcLineY (current, total, size) {
    const totalLineHeight = total * size
    const padding = Math.floor(PAGE_HEIGHT - totalLineHeight)
    return current === 1
      ? (padding / 2) - 8
      : (padding / 2) + ((current - 1) * size) - 8
  }

  function yCalc (_doc, text, fontSize) {
    const textWidth = _doc.getTextWidth(text)
    const lineCount = Math.ceil(textWidth / LINE_WIDTH)
    const linesHeight = lineCount * (fontSize)
    const y = ((PAGE_HEIGHT - linesHeight) / 2)
    return y
  }

  function save () {
    const date = moment().format('DD/MM/YYYY_h:mm_a')
    const filename = 'Labels_' + date + '.pdf'
    doc.save(filename)
  }

  function start () {
    labels.forEach(function (label, labelIndex) {
      doc.setFontSize(20)
      doc.setFont('Neuzeit-Grotesk')
      /** Address Label */
      if (label.address.line_2) {
        doc.text(label.address.line_1, 20, calcLineY(1, 4, 20))
        doc.text(label.address.line_2, 20, calcLineY(2, 4, 20))
        doc.text(label.address.line_3, 20, calcLineY(3, 4, 20))
        doc.text(label.address.line_4, 20, calcLineY(4, 4, 20))
      } else {
        doc.text(label.address.line_1, 20, calcLineY(1, 3, 20))
        doc.text(label.address.line_3, 20, calcLineY(2, 3, 20))
        doc.text(label.address.line_4, 20, calcLineY(3, 3, 20))
      }
      doc.text('#' + label.id.toString(), 190, 20)
      doc.setFontSize(8)
      doc.text(label.skus.join(' | '), 20, 100)

      /**
       * Add Pages When Multiple Skus
       */
      if (label.skus.length > 1) {
        doc.addPage()
        doc.setFont('Neuzeit-Grotesk')
        doc.setFontSize(20)
        label.skus.forEach(function (sku, idx) {
          doc.text((idx + 1) + ' of ' + label.skus.length, 20, calcLineY(1, 1, 20))
          doc.text(sku, 20, calcLineY(2, 1, 20))
          doc.text('#' + label.id.toString(), 190, 20)
          if ((idx + 1) < label.skus.length) doc.addPage()
        })
      }

      /** Message Label */
      if (label.message) {
        doc.addPage()
        doc.setFont('Caveat-Regular', 'normal')
        doc.setFontSize(20)
        var opts = { maxWidth: LINE_WIDTH }
        doc.text(label.message, 10, yCalc(doc, label.message, 20), opts)
      } else {
        doc.addPage()
        doc.setFont('Caveat-Regular', 'normal')
        doc.setFontSize(20)
        doc.text('/*** Leave Card Blank ***/', 10, yCalc(doc, label.message, 20), opts)
      }


      /** Add Page */
      if ((labelIndex + 1) < labels.length) {
        doc.addPage()
      }
    })

    save()
  }

  start()
}
