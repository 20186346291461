exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".print-labels__row___1uJD- {\n  margin: 20px 0;\n}", ""]);

// exports
exports.locals = {
	"row": "print-labels__row___1uJD-"
};