import React from 'react'
import PropTypes from 'prop-types'

import {Route, Link} from 'react-router-dom'
import {Grid, Row, Col} from '@jetbrains/ring-ui/components/grid/grid'

import styles from './page.css'

function Page (props) {
  return (
    <Grid className={styles.page}>
      <Row start="xs">
        <Col xs={12} sm={12} md={12} lg={12}>
          <h1>{props.title}</h1>
        </Col>
      </Row>
      <Row start="xs">
        <Col xs={12} sm={12} md={12} lg={12}>
          <div>
            {props.children}
          </div>
        </Col>
      </Row>
    </Grid>
  )
}

Page.propTypes = {
  title: PropTypes.string.isRequired,
}

export default Page