import React, { Component } from 'react';
import Modal from '../shared/modal';

import Auth from '@jetbrains/ring-ui/components/auth/auth';
import Header from './header'
import Main from './main'

export default class AppRoot extends Component {
  constructor() {
    super();

    this.state = {
      modalIsOpen: false
    }
  }

  componentDidMount () {
    // You can uncomment this after registering your client as a Hub service
    // https://www.jetbrains.com/help/hub/2017.3/OAuth-2.0-Authorization.html#d79479e312
    // this.auth.init();
  }

  modalOpen = () => {
    this.setState({ modalIsOpen: true })
  }

  modalAfterOpen = () => {
    // this.subtitle.style.color = '#f00'
  }

  modalClose = () => {
    this.setState({ modalIsOpen: false })
  }

  auth = new Auth({
    // clientId: <your client id here>
    serverUri: 'https://hub.jetbrains.com' // replace with your Hub server
  })

  render () {
    const modalProps = {
      modalIsOpen: this.state.modalIsOpen,
      modalOpen: this.modalOpen,
      modalClose: this.modalClose,
      modalAfterOpen: this.modalAfterOpen
    }

    return (
      <div id="boo">
        <Header auth={this.auth} />
        <Main {...modalProps} />
        <Modal {...modalProps} />
      </div>
    );
  }
}