import React, { useState } from 'react'
import moment from 'moment'
import DatePicker from '@jetbrains/ring-ui/components/date-picker/date-picker'
import Dialog from '@jetbrains/ring-ui/components/dialog/dialog'
import { Header, Content } from '@jetbrains/ring-ui/components/island/island'
import { Grid, Row, Col } from '@jetbrains/ring-ui/components/grid/grid'
import Page from '../../layout/page'

import API from '../../../lib/api'

// Utils :: Ramda Imports
const assoc = require('ramda/src/assoc')
const append = require('ramda/src/append')
const compose = require('ramda/src/compose')
const findIndex = require('ramda/src/findIndex')
const groupBy = require('ramda/src/groupBy')
const length = require('ramda/src/length')
const map = require('ramda/src/map')
const mapObjIndexed = require('ramda/src/mapObjIndexed')
const prop = require('ramda/src/prop')
const propEq = require('ramda/src/propEq')
const reduce = require('ramda/src/reduce')
const sortBy = require('ramda/src/sortBy')
const update = require('ramda/src/update')

/***
 * Utils :: Data Formating
 */

// byDate :: array => obj
const byDate = groupBy(prop('date'))

// byState :: obj => obj
const byState = mapObjIndexed((val, key, obj) => compose(count, groupBy(prop('state')))(val))

// count :: obj = > obj
const count = mapObjIndexed((val, key, _obj) => length(val))

// sort :: array => array
const sort = sortBy(prop('date'))

// int :: maybe int => int
const int = x => x || 0

// sum :: maybe int, maybe int => int
const sum = (x, y) => int(x) + int(y)

// toArray :: obj => array obj
const toArray = obj => {
  return compose(
    map(key => ({
      date: key,
      'vic': obj[key]['VIC'],
      'nsw': obj[key]['NSW'],
      'qld': obj[key]['QLD']
    })),
    Object.keys
  )(obj)
}

// Utils :: Rendering
const makeRows = compose(sort, toArray, byState, byDate)
const renderDate = date => moment(date).format('dddd, Do MMMM YYYY')
const renderTableRows = (data, fetchLineItems) => compose(
  map(renderRow(fetchLineItems)),
  makeRows
)(data)

const clickable = { cursor: 'pointer' }

const renderRow = fetchLineItems => ({ date, vic, nsw, qld }) => {
  return (
    <Row key={date}>
      <Col xs={12} sm={2} md={2} lg={2}>
        {renderDate(date)}
      </Col>
      <Col xs={12} sm={2} md={2} lg={2}>
        <p onClick={(evt) => fetchLineItems('VIC', date)} style={clickable}>
          {int(vic)}
        </p>
      </Col>
      <Col xs={12} sm={2} md={2} lg={2}>
        <p onClick={(evt) => fetchLineItems('NSW', date)} style={clickable}>
          {int(nsw)}
        </p>
      </Col>
      <Col xs={12} sm={2} md={2} lg={2}>
        <p onClick={(evt) => fetchLineItems('QLD', date)} style={clickable}>
          {int(qld)}
        </p>
      </Col>
      <Col xs={12} sm={2} md={2} lg={2}>
        <p onClick={(evt) => fetchLineItems('', date)} style={clickable}>
          {sum(vic, nsw)}
        </p>
      </Col>
    </Row>
  )
}

const renderTableHeader = (data) => {
  if (length(data)) {
    return (
      <Row>
        <Col xs={2} sm={2} md={2} lg={2}>
          <h3>DATE</h3>
        </Col>
        <Col xs={2} sm={2} md={2} lg={2}>
          <h3>VIC</h3>
        </Col>
        <Col xs={2} sm={2} md={2} lg={2}>
          <h3>NSW</h3>
        </Col>
        <Col xs={2} sm={2} md={2} lg={2}>
          <h3>QLD</h3>
        </Col>
        <Col xs={2} sm={2} md={2} lg={2}>
          <h3>TOTAL</h3>
        </Col>
      </Row>
    )
  } else {
    return (
      <Row>
        <div style={{ paddingTop: '20px' }}>
          Nothing to render yet...
        </div>
      </Row>
    )
  }
}

const renderTableFooter = (data) => {
  const vic = data.filter(propEq('state', 'VIC')).length
  const nsw = data.filter(propEq('state', 'NSW')).length
  const qld = data.filter(propEq('state', 'QLD')).length
  const total = vic + nsw
  if (total === 0) return null
  return (
    <Row>
      <Col xs={2} sm={2} md={2} lg={2} />
      <Col xs={2} sm={2} md={2} lg={2}>
        <strong>{vic}</strong>
      </Col>
      <Col xs={2} sm={2} md={2} lg={2}>
        <strong>{nsw}</strong>
      </Col>
      <Col xs={2} sm={2} md={2} lg={2}>
        <strong>{qld}</strong>
      </Col>
      <Col xs={2} sm={2} md={2} lg={2}>
        <strong>{total}</strong>
      </Col>
    </Row>
  )
}

const renderItem = item => (
  <div>{item.name} x {item.quantity}</div>
)

const countItems = (acc, item) => {
  const index = findIndex(propEq('name', item.name))(acc)
  if (index === -1) return append({ name: item.name, quantity: item.quantity }, acc)
  const prev = acc[index]
  const next = assoc('quantity', prev.quantity + item.quantity, prev)
  return update(index, next, acc)
}

const formatName = name =>
  name
    .replace(' - Sydney', '')
    .replace(' - Melbourne', '')
    .replace(', Sydney', '')
    .replace(', Melbourne', '')
    .replace('- Small', ' (Small)')
    .replace('- Regular', ' (Regular)')
    .replace('- Large', ' (Large)')
    .replace('- Extra Large', ' (Extra Large)')
    .replace('- WOW!', ' (WOW)')
    .replace(', Small', ' (Small)')
    .replace(', Regular', ' (Regular)')
    .replace(', Large', ' (Large)')
    .replace(', Extra Large', ' (Extra Large)')
    .replace(', WOW!', ' (WOW)')
    .replace('- 30-hour burn time', '(30H)')
    .replace('- 55-hour burn time', '(55H)')
    .replace('- 30cm White', '(White)')
    .replace('- 25cm Glass', '(25cm)')
    .replace('- 35cm Glass', '(35cm)')
    .replace('- 23cm', '(23cm)')
    .replace('- 28cm', '(28cm)')
    .replace(', One Dozen', ' (One Doz.)')
    .replace(', Two Dozen', ' (Two Doz.)')
    .replace(', Three Dozen', ' (Three Doz.)')

const renderLineItems = compose(
  map(renderItem),
  sortBy(prop('name')),
  reduce(countItems, []),
  map(item => assoc('name', formatName(item.name), item))
)

const DeliveryList = ({ data, fetchLineItems }) => (
  <Grid>
    {renderTableHeader(data)}
    {renderTableRows(data, fetchLineItems)}
    {renderTableFooter(data)}
  </Grid>
)

function SalesReport () {
  const [from, setFrom] = useState('')
  const [to, setTo] = useState('')
  const [deliveries, setDeliveries] = useState([])
  const [lineItems, setLineItems] = useState([])
  const [isDialogVisible, setDialogVisbility] = useState(false)

  const setRange = ({ from, to }) => {
    setFrom(from)
    setTo(to)
    if (from && to) fetchData(from, to)
  }

  const fetchData = (from, to) => {
    const params = {
      from: moment(from).startOf('day').toISOString(),
      to: moment(to).endOf('day').toISOString()
    }

    API.get('/deliveries', { params }).then(function (response) {
      setDeliveries(response.data.deliveries)
    }).catch(function (error) {
      console.error(error)
    })
  }

  const openDialog = () => setDialogVisbility(true)

  const closeDialog = (evt) => setDialogVisbility(false)

  const fetchLineItems = (state, date) => {
    API.get(`/line-items?from=${date}&to=${date}&state=${state}`)
      .then(response => {
        setLineItems(response.data)
        openDialog()
      }).catch(error => {
        console.log(error)
      })
  }

  return (
    <Page title='Sales Report'>
      <div>
        <div>
          <DatePicker
            range
            from={from}
            to={to}
            onChange={setRange}
          />
        </div>
        <DeliveryList
          data={deliveries}
          fetchLineItems={fetchLineItems}
        />
        <Dialog
          show={isDialogVisible}
          onCloseAttempt={closeDialog}
          showCloseButton
          trapFocus
          autoFocusFirst={false}
        >
          <Header>Items</Header>
          <Content>
            {renderLineItems(lineItems)}
          </Content>
        </Dialog>
      </div>
    </Page>
  )
}

export default SalesReport
