import useSWR from 'swr'
import fetch from '../lib/api'

export default function useProducts (query) {
  let url = '/woocommerce/products'

  if (query) {
    let params = '?'

    Object.keys(query).forEach((key, index) => {
      if (index === 0) {
        params += `${key}=${query[key]}`
      } else {
        params += `&${key}=${query[key]}`
      }
    })

    url += params
  }

  const { data, error } = useSWR(url, fetch)

  return {
    data: data ? data.data : undefined,
    error,
    loading: !error && !data
  }
}
