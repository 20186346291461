import find from 'ramda/src/find'
import compose from 'ramda/src/compose'
import not from 'ramda/src/not'
import isNil from 'ramda/src/isNil'
import propEq from 'ramda/src/propEq'
import curry from 'ramda/src/curry'
import head from 'ramda/src/head'

import { tokenize } from '../lib/transformers'

import { getFromMetaData } from './woocommerce'

const includes = value => collection => collection.includes(value)

const filters = {
  yello: {
    orders: {
      byStore: store => order =>
        (tokenize(store) === 'all'
          ? order
          : tokenize(order.storeReference) === store),

      isSameOrderState: state => order =>
        (tokenize(state) === 'all'
          ? order
          : tokenize(order.state) === state),

      isNotInListOfIds: curry((ids, order) =>
        compose(
          not,
          includes(order._id)
        )(ids))
    }
  },
  woocommerce: {
    orders: {
      isRegion: curry((region, order) => {
        if (region === 'all') {
          return true
        }
        if (region === 'melbourne') {
          return head(order.shipping.postcode) === '3'
        }
        if (region === 'sydney') {
          return head(order.shipping.postcode) === '2'
        }
        return false
      }),
      isDeliveryDate: curry((date, order) => {
        const deliveryDate = getFromMetaData('delivery_date', null, order)
        if (date === 'all') {
          return true
        }
        if (date === deliveryDate) {
          return true
        }
        return false
      }),
      isYello: curry((yello, order) =>
        compose(
          isNil,
          find(propEq('orderReference', order.id.toString()))
        )(yello)
      ),
      isNotExported: curry((exported, order) =>
        compose(
          not,
          ids => ids.includes(order.id)
        )(exported))
    }
  }
}

export default filters
