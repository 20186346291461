/* eslint-disable no-undef */
import axios from 'axios'

const baseURL = process.env.API_URL
if (!baseURL) {
  throw new Error('Missing environment variable API_URL')
}

export default axios.create({
  baseURL,
  timeout: 60000
})
