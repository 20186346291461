import compose from 'ramda/src/compose'
import replace from 'ramda/src/replace'
import curry from 'ramda/src/curry'

const toImgixUrl = curry((
  fallback = 'http://placehold.it/600x600',
  query = '',
  str
) => {
  if (!str) return fallback

  const endpoint = 'figandbloomwordpress.imgix.net'

  return compose(
    str => str + query,
    replace('staging.', ''),
    replace('http://', 'https://'),
    replace('figandbloom.com', endpoint),
    replace('www.', ''),
    replace('mk0ecommercedny076y1.kinstacdn.com', endpoint)
  )(str)
})

export default toImgixUrl
