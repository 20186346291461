import React from 'react'
import { Route, Switch } from 'react-router-dom'

import Home from '../containers/home'
import ProductsPage from '../pages/products-page'
import Product from '../products/product'
import ExportOrdersToYello from '../containers/orders/export-orders-to-trello'
import SalesReport from '../containers/orders/sales-report'
import PrintLabels from '../containers/orders/print-labels'

const { version } = require('../../../package.json')

const Version = () => {
  return (
    <h1 style={{padding: '50px'}}>Version {version}</h1>
  )
}

export default (props) => (
  <main>
    <Switch>
      <Route exact path='/' component={Home} />
      <Route exact path='/version' component={Version} />
      <Route exact path='/labels' component={PrintLabels} />
      <Route exact path='/couriers' component={ExportOrdersToYello} />
      <Route exact path='/sales' component={SalesReport} />
      <Route path='/products' component={ProductsPage} />
      <Route path='/products/:slug' component={Product} />
    </Switch>
  </main>
)
