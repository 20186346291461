import React from 'react'
import { Link } from 'react-router-dom'

import {Grid, Row, Col} from '@jetbrains/ring-ui/components/grid/grid'

import Page from '../layout/page'

import styles from './home.css'
import bgCouriers from '../../assets/couriers.jpg'
import bgLabels from '../../assets/labels.jpg'
import bgProducts from '../../assets/products.jpg'
import bgSales from '../../assets/sales.jpg'

const links = [
  {
    title: 'Couriers',
    href: '/couriers',
    image: bgCouriers
  },
  {
    title: 'Sales Report',
    href: '/sales',
    image: bgSales
  },
  {
    title: 'Print Labels',
    href: '/labels',
    image: bgLabels
  },
  {
    title: 'Products',
    href: '/products',
    image: bgProducts
  },
]

export default function Home () {
    return (
      <Page>
        <Grid data-test='auto-size'>
          <Row>
            {links.map(link => (
              <Col xs>
                <div
                  className={styles.container}
                    style={{
                      backgroundImage: `url(${link.image})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                    }}
                >
                  <Link
                    to={link.href}
                    className={styles.link}
                  >
                    {link.title}
                  </Link>

                </div>
              </Col>
            ))}
          </Row>
        </Grid>
      </Page>
    )
}