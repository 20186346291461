import React from 'react';
import PropTypes from 'prop-types';

import Select from '@jetbrains/ring-ui/components/select/select';
import Popup from '@jetbrains/ring-ui/components/popup/popup';

function Filter(props) {
  return (
    <div>
      {
        [
          props.label,
          <Select
            filter={props.filter}
            key="select"
            multiple={props.multiple || null}
            type={Select.Type.INLINE}
            selected={props.selected}
            data={props.options}
            directions={[Popup.PopupProps.Directions.BOTTOM_RIGHT]}
            onChange={props.onChange}
          />
        ]
      }
    </div>
  );
}

Filter.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired
  })).isRequired,
  selected: PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired
  }).isRequired,
  onChange: PropTypes.func.isRequired
};

export default Filter;
