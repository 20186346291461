import React from 'react'
import {Grid, Row, Col} from '@jetbrains/ring-ui/components/grid/grid'
import Select from '@jetbrains/ring-ui/components/select/select';

import API from '../../lib/api'
import Filter from '../forms/filter'
import styles from './trello-select.css'

const R = require('ramda')

const Hideable = props => props.data ? props.children : null
const noop = () => {}
const nullOption = { key: "", label: "", type: "user" }
const formatOption = item => ({ key: item.id, label: item.name, type: 'user' })
const isOrders = R.compose(R.test(/orders/gi), R.prop('name'))
const isNotTest = R.compose(R.not, R.test(/test/gi), R.prop('name'))

class TrelloSelect extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      boardOptions: [],
      boardSelected: nullOption,
      listOptions: [],
      listSelected: nullOption,
      cards: [],
    }
  }

  componentDidMount () {
    API
    .get('/trello/boards')
    .then(R.compose(
      boardOptions => this.setState({ boardOptions }),
      R.map(formatOption),
      R.filter(isNotTest),
      R.filter(isOrders),
      R.prop('data')))
  }

  componentDidUpdate (_, previous) {
    if (this.isBoardUpdated(previous, this.state)) {
      this.props.onUpdateBoard()
      this.fetchLists(this.state.boardSelected.key)
    }

    if (this.isListUpdated(previous, this.state)) {
      this.props.onUpdateList()
      this.fetchCards(this.state.listSelected.key)
    }
  }

  isBoardUpdated = (previous, current) => {
    return current.boardSelected.key !== previous.boardSelected.key
  }

  isListUpdated = (previous, current) => {
    return current.listSelected.key
      && (current.listSelected.key !== previous.listSelected.key)
  }

  fetchLists = () => {
    API
    .get(`/trello/boards/${this.state.boardSelected.key}/lists`)
    .then(R.compose(
      listOptions => this.setState({
        listOptions,
        listSelected: nullOption
      }),
      R.map(formatOption),
      R.prop('data')))
  }

  fetchCards = () => {
    if (this.state.listSelected.key) {
      API
      .get(`/trello/lists/${this.state.listSelected.key}/cards`)
      .then(R.prop('data'))
      .then(this.props.onCards)
      .catch(console.error)
    }
  }

  updateBoard = (boardSelected) => {
    this.props.onUpdateBoard()
    this.setState({ labels: [], cards: [], listOptions: [], boardSelected })
  }

  updateList = (listSelected) => {
    this.props.onUpdateList()
    this.fetchCards()
    this.setState({ labels: [], cards: [], listSelected })
  }

  render () {
    return (
      <div>
        <Row className={styles.row}>
          <Filter
            label='Board: '
            options={this.state.boardOptions}
            onChange={this.updateBoard}
          />
        </Row>
        <Row className={styles.row}>
          <Hideable data={this.state.boardSelected.key}>
            <Filter
              label='List: '
              options={this.state.listOptions}
              onChange={this.updateList}
            />
          </Hideable>
        </Row>
      </div>
    )
  }
}

export default TrelloSelect
