import moment from 'moment'

import compose from 'ramda/src/compose'
import curry from 'ramda/src/curry'
import filter from 'ramda/src/filter'
import find from 'ramda/src/find'
import findIndex from 'ramda/src/findIndex'
import head from 'ramda/src/head'
import ifElse from 'ramda/src/ifElse'
import isNil from 'ramda/src/isNil'
import isEmpty from 'ramda/src/isEmpty'
import map from 'ramda/src/map'
import match from 'ramda/src/match'
import prop from 'ramda/src/prop'
import propEq from 'ramda/src/propEq'
import replace from 'ramda/src/replace'
import uniq from 'ramda/src/uniq'

export function flatten (a, b) {
  return a.concat(b)
}

export function formatLineItemName (item, _) {
  const base = `${item.quantity}x ${item.name}`
  return base
    .replace('Sydney', '')
    .replace('Melbourne', '')
    .replace(',', '')
}

export function isValidSize (size) {
  switch (size) {
    case 'regular': {
      return true
    }
    case 'large': {
      return true
    }
    case 'extra-large': {
      return true
    }
    case 'deluxe': {
      return true
    }
    default: {
      return false
    }
  }
}

export function getLineItemNames (order, size) {
  return order.line_items
    .map(item => formatLineItemName(item, size))
    .join(' + ')
    .replace('Rustic | ', '')
    .replace('Pretty & Pink | ', '')
    .replace('Bright & Colourful | ', '')
    .replace('Contemporary White | ', '')
}

export function getLineItemMetaData (lineItems, key, empty) {
  const list = lineItems
    .map(item => find(propEq('key', key))(item.meta_data))
    .map(size => (size && 'value' in size ? size.value : ''))
    .filter(isValidSize)

  return isEmpty(list) ? empty : list[0]
}

export const getFromMetaData = curry((key, fallback, order) => {
  const filtered = order.meta_data.filter(i => i.key === key)
  return filtered.length > 0 ? filtered[0].value : fallback
})

export function getDeliveryTimeframe (order) {
  const pattern = /Before \d{1,2} (AM|PM)/i
  const excluded = /Before 5 PM/i
  const match = order.shipping_lines
    .map(prop('method_title'))
    .filter(str => pattern.test(str))
    .filter(str => !excluded.test(str))
  return match.length ? ` ${match[0]} 🚚` : ''
}

export function sortOrdersByDeliveryDate (orders) {
  return orders
    .reduce((acc, order) => {
      // exit the loop of no delivery date
      const deliveryDate = getFromMetaData('delivery_date', null, order)
      if (!deliveryDate) {
        return acc
      }
      // add orders for date
      const index = findIndex(propEq('deliveryDate', deliveryDate), acc)
      if (index === -1) {
        return [...acc, { deliveryDate, orders: [order] }]
      }
      // extend orders for date
      acc[index].orders = [...acc[index].orders, order]
      return acc
    }, [])
    .sort((a, b) => {
      const dateA = moment(a.deliveryDate, 'MMMM DD, YYYY')
      const dateB = moment(b.deliveryDate, 'MMMM DD, YYYY')
      if (dateA.isAfter(dateB)) {
        return 1
      }
      if (dateA.isBefore(dateB)) {
        return -1
      }
      return 0
    })
}

// containsOrderId :: String -> Array
export const containsOrderId = (str) => RegExp(/^\#\d+/g).test(str)

// extractOrderid :: String -> Array
export const extractOrderId = (str) =>
  str === '' ? '' : str.match(/^\#\d+/g).join('')

// removeHash :: Array -> String
export const removeHash = s => s.replace('#', '')  

export const idsFromCards = compose(
  uniq,
  map(removeHash),
  map(extractOrderId),
  filter(containsOrderId),
  map(prop('label')),
)

export const orderIdFromTrelloCardName = (s) => {
  const id = compose(
    ifElse(isNil, () => "", Number),
    head,
    match(/\d+/gi),
    replace("#", "")
  )(s)
  if (!id) return null
  if (id < 200000) return null
  return id
}

// export const orderIdFromTrelloCardName = compose(
//   removeHash,
//   extractOrderId,
//   str => containsOrderId(str) ? str : '',
// )
