import alert from '@jetbrains/ring-ui/components/alert-service/alert-service'

import curry from 'ramda/src/curry'
import path from 'ramda/src/path'
import prop from 'ramda/src/prop'

import API from '../lib/api'

import { fetchOrders as fetchYelloOrders } from './yello'

const durationSuccessMsg = 2000
const durationErrorMsg = 5000

export const editWooCommerceOrder = curry(async (dispatch, id, updates) => {
  try {
    const payload = await API.put(`/woocommerce/orders/${id}`, updates).then(prop('data'))
    alert.message(`✅ Updated order #${id}`, durationSuccessMsg)
    dispatch({ type: 'WOOCOMMERCE_ORDER_UPDATE_SUCCESS', payload })
    return payload
  } catch (err) {
    const payload = err.message
    alert.error(`Failed to update order #${id}`, durationErrorMsg)
    dispatch({ type: 'WOOCOMMERGE_ORDER_UPDATE_ERROR', payload })
    return payload
  }
})

export const fetchOrders = curry(async (dispatch, queryString) => {
  try {
    dispatch({ type: 'WOOCOMMERCE_ORDERS_FETCH_REQUEST', payload: 'active' })
    const orders = await API.get('/woocommerce/orders' + queryString).then(prop('data'))
    alert.message('✅ Fetched orders from WooCommerce.', durationSuccessMsg)
    dispatch({ type: 'WOOCOMMERCE_ORDERS_FETCH_SUCCESS', payload: orders })
    return orders
  } catch (err) {
    const payload = err.message
    alert.error('Failed to fetch orders from WooCommerce', durationErrorMsg)
    dispatch({ type: 'WOOCOMMERCE_ORDERS_FETCH_ERROR', payload })
    return payload
  }
})

export const exportToYello = curry(async (dispatch, id, params = {}) => {
  try {
    dispatch({ type: 'YELLO_EXPORT_SUCCESS', payload: id })
    const payload = await API.post(`/woocommerce/orders/${id}/yello`)
    alert.message(`✅ Order #${id}: Added to Yello`, durationSuccessMsg)
    fetchYelloOrders(dispatch, params)
    return payload
  } catch (err) {
    const response = path(['response', 'data', 'message'], err)
    const message = `Failed to export order #${id}. ${response || err.message}`
    dispatch({ type: 'YELLO_EXPORT_ERROR', payload: id })
    alert.error(message, durationErrorMsg)
    return message
  }
})

// export function searchOrders(dispatch) {
//   return async query => {
//     try {
//       const payload = await API.post('/woocommerce/orders/search', {query});
//       dispatch({type: 'WOOCOMMERCE_SEARCH_SUCCESS', payload});
//       console.log(payload);
//       return payload;
//     } catch (err) {
//       alert.error('Search failed. Please refer to logs', durationErrorMsg);
//       console.error(err);
//       dispatch({type: 'WOOCOMMERCE_SEARCH_ERROR', payload: err.message});
//       return err;
//     }
//   };
// }
