import React from 'react';
import PropTypes from 'prop-types';
import path from 'ramda/src/path';

import Tag from '@jetbrains/ring-ui/components/tag/tag';
import Island, {Content} from '@jetbrains/ring-ui/components/island/island';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrash, faPencilAlt} from '@fortawesome/free-solid-svg-icons';

import {titleize} from '../../lib/transformers';


function YelloOrder(props) {
  const orderId = `#${props.order.orderReference}`;
  const tagText = titleize(props.order.state);

  const deliveryAddress = path(
    ['deliveryAddress', 'addressText'],
    props.order
  );

  function handleClick() {
    props.onDelete(props.order._id, {state: props.order.state});
  }

  return (
    <Island>
      <Content>
        <FontAwesomeIcon
          icon={faTrash}
          onClick={handleClick}
        />
        <FontAwesomeIcon
          icon={faPencilAlt}

        />
        <p>{orderId} <Tag readOnly>{tagText}</Tag></p>
        <p>{deliveryAddress}</p>
      </Content>
    </Island>
  );
}

YelloOrder.propTypes = {
  order: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    orderReference: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired
  }),
  onDelete: PropTypes.func.isRequired
};

export default YelloOrder;
