exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".page__page___2hiuT {\n  padding: 100px 50px;\n}", ""]);

// exports
exports.locals = {
	"page": "page__page___2hiuT"
};