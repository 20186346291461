import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import titleize from 'titleize'

import {Grid, Row, Col} from '@jetbrains/ring-ui/components/grid/grid'
import Button from '@jetbrains/ring-ui/components/button/button'
import Select from '@jetbrains/ring-ui/components/select/select';

import Filter from '../../forms/filter'
import LabelList from '../../labels/label-list'
import Page from '../../layout/page'

import API from '../../../lib/api'
import { idsFromCards, orderIdFromTrelloCardName } from '../../../lib/woocommerce'
import { toUpperCase } from '../../../lib/transformers'
import { print } from '../../../lib/pdf'

import styles from './print-labels.css'

const Entities = require('html-entities').XmlEntities;
const entities = new Entities();
const R = require('ramda')

const noop = () => {}

const formatOption = item => ({
  key: item.id,
  label: item.name,
  type: 'user'
})

const isOrders = R.compose(R.test(/orders/gi), R.prop('name'))

const isNotTest = R.compose(R.not, R.test(/test/gi), R.prop('name'))

const Hideable = props => props.data ? props.children : null

const nullOption = { key: "", label: "", type: "user" }

const extractSkus = R.compose(
  R.map(R.replace('-brut-royal-nv', '')),
  R.map(R.replace('-sydney', '')),
  R.map(R.replace('-melbourne', '')),
  R.map(item => R.prop('sku', item) || R.prop('name', item)),
  R.prop('line_items')
)

function toLabel (order) {
  const first_name = titleize(order.shipping.first_name)
  const last_name = titleize(order.shipping.last_name)
  const company = titleize(order.shipping.company)
  const address_1 = titleize(order.shipping.address_1)
  const address_2 = titleize(order.shipping.address_2)
  const city = titleize(order.shipping.city)
  const state = toUpperCase(order.shipping.state)
  const postcode = order.shipping.postcode
  const customer_note = entities.decode(order.customer_note)

  return {
    id: order.id,
    skus: extractSkus(order),
    address: {
      line_1: first_name + ' ' + last_name,
      line_2: company !== '' ? 'C/- ' + company : null,
      line_3: address_2 ? address_2 + ', ' + address_1 : address_1,
      line_4: city + ' ' + state + ' ' + postcode
    },
    message: customer_note
  }
}

const insertEmoji = R.curry(function (emoji, string) {
  return R.compose(
    R.join(' '),
    R.ifElse(R.contains(emoji), R.identity, R.insert(2, emoji)),
    R.split(' ')
  )(string)
})

async function updateTrelloCard (cards) {
  try {
    for (let i = 0; i < cards.length; i++) {
      const idCard = cards[i].id
      const name = cards[i].name.trim()
      const idOrder = orderIdFromTrelloCardName(name)
      // skip cards that don't contain order id
      if (idOrder === '') continue
      // update card name
      if (cards[i].name.includes('CARD PRINTED') === false) {
        await API.put('/trello/cards/' + idCard, {
          name: insertEmoji('📩', name)
        })
      }
      // add comment
      await API.post(`/trello/cards/${idCard}/actions/comments`, {
        text: 'Printed card note via dashboard.figandbloom.com.au'
      })
      // check the box
      await API.put(`/trello/cards/${idCard}/checkitem/update`, {
        listName: 'Checklist',
        itemName: 'Card',
        itemParams: { state: 'complete' }
      })
    }
  } catch (err) {
    console.error(err)
  }
}

class PrintLabels extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      boardOptions: [],
      boardSelected: nullOption,
      listOptions: [],
      listSelected: nullOption,
      cardOptions: [],
      cardsSelected: [],
      labels: []
    }
  }

  componentDidMount () {
    API
    .get('/trello/boards')
    .then(R.compose(
      boardOptions => this.setState({ boardOptions }),
      R.map(formatOption),
      R.filter(isNotTest),
      R.filter(isOrders),
      R.prop('data')))
  }

  componentWillUpdate (nextProps, nextState) {
    if (nextState.cardsSelected.length === this.state.cardsSelected.length) return
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.boardSelected.key !== prevState.boardSelected.key) {
      API
      .get(`/trello/boards/${this.state.boardSelected.key}/lists`)
      .then(R.compose(
        listOptions => this.setState({
          listOptions,
          listSelected: nullOption
        }),
        R.map(formatOption),
        R.prop('data')))
    }

    if (this.state.listSelected.key && (this.state.listSelected.key !== prevState.listSelected.key)) {
      API
      .get(`/trello/lists/${this.state.listSelected.key}/cards`)
      .then(R.compose(
        cardOptions => this.setState({ cardOptions }),
        R.map(formatOption),
        R.prop('data')))
    }
  }

  updateBoard = (value) => {
    this.setState({
      labels: [],
      cards: [],
      boardSelected: value,
      listOptions: []
    })
  }

  updateList = (value) => {
    this.setState({
      labels: [],
      cards: [],
      listSelected: value
    })
  }

  /**
   * updateLabel :: (String, Array(String), String) => object
   */
  updateLabel = (id, attrPath, attrValue) => {
    const updateItem = item =>
      item.id !== id ? item : R.assocPath(attrPath, attrValue)(item)
    this.setState({ labels: R.map(updateItem)(this.state.labels) })
  }

  /** TODO :: Avoid the global vars **/
  __update = data => {
    window.__MULTIPLE_SELECT___ = data
  }

  /** MORE MUTATION */
  generateLabels = () => {
    if (!window.__MULTIPLE_SELECT___.length) return
    const idsJoined = idsFromCards(window.__MULTIPLE_SELECT___).join(',')

    const cards = R.map(select => ({
      id: select.key,
      name: select.label
    }))(window.__MULTIPLE_SELECT___)
    
    API
    .get(`/woocommerce/orders?status=any&include=${idsJoined}`)
    .then(R.prop('data'))
    .then(R.map(toLabel))
    .then(labels => this.setState({ labels }))
    .then(() => updateTrelloCard(cards))
    .catch(console.error)
  }

  render () {
    const {
      boardOptions,
      listOptions,
      cardOptions,
      boardSelected,
      listSelected,
      cardsSelected,
      labels
    } = this.state

    return (
      <Page title='Print Labels'>
        <Grid>
          <Row className={styles.row}>
            <Filter
              label='Board: '
              options={boardOptions}
              onChange={this.updateBoard}
            />
          </Row>
          <Row className={styles.row}>
            <Hideable data={boardSelected.key}>
              <Filter
                label='List: '
                options={listOptions}
                onChange={this.updateList}
              />
            </Hideable>
          </Row>
          <Row className={styles.row}>
            <Hideable data={listSelected.key}>
              <Select
                label='Select cards'
                filter
                multiple={{selectAll: true}}
                selected={[]}
                data={cardOptions}
                onSelect={noop}
                onDeselect={noop}
                onChange={e => this.__update(e)}
              />
            </Hideable>
          </Row>
          <Row className={styles.row}>
            <Hideable data={labels && labels.length === 0}>
              <Button
                key='default'
                default
                onClick={e => this.generateLabels()}
              >
                {'Generate Labels'}
              </Button>
            </Hideable>
          </Row>
          <Row className={styles.row}>
            <Hideable data={labels && labels.length}>
              <Button
                key='primary'
                primary
                onClick={e => print(labels)}
              >
                {'Print Labels'}
              </Button>
            </Hideable>
          </Row>
          <LabelList
            labels={labels}
            update={this.updateLabel}
          />
        </Grid>
      </Page>
    )
  }
}

export default PrintLabels
