import React, { useEffect } from 'react'
import {Grid} from '@jetbrains/ring-ui/components/grid/grid'

import LabelItem from './label-item'

import styles from './label-list.css'

const LabelList = ({ labels, update }) => {
  return (
    <div className={styles.container}>
      {labels.map(label =>
        <LabelItem
          key={label.id}
          id={label.id}
          address_1={label.address.line_1}
          address_2={label.address.line_2}
          address_3={label.address.line_3}
          address_4={label.address.line_4}
          message={label.message}
          skus={label.skus}
          update={update}
        />)}
    </div>
  )
}

export default LabelList
