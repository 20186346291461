exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".products-page__root___3LTSI {\n  margin-bottom: 200px;\n}\n\n.products-page__buttons___1Nr2v {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 25px 0;\n}\n", ""]);

// exports
exports.locals = {
	"root": "products-page__root___3LTSI",
	"buttons": "products-page__buttons___1Nr2v"
};