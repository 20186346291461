exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".home__container___1TLd7 {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 350px;\n  width: 350px;\n}\n\n.home__link___3U1VD {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n  width: 100%;\n  color: #fff;\n  background-color: rgba(0, 0, 0, 0.3);\n  font-size: 2rem;\n  text-decoration: none;\n}\n\n", ""]);

// exports
exports.locals = {
	"container": "home__container___1TLd7",
	"link": "home__link___3U1VD"
};