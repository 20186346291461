import curry from 'ramda/src/curry';
import compose from 'ramda/src/compose';
import head from 'ramda/src/head';
import map from 'ramda/src/map';
import path from 'ramda/src/path';
import pathOr from 'ramda/src/pathOr';
import pickAll from 'ramda/src/pickAll';
import prop from 'ramda/src/prop';

import alert from '@jetbrains/ring-ui/components/alert-service/alert-service';

import API from '../lib/api';

  const durationSuccessMsg = 2000;
  const durationErrorMsg = 5000;

export const fetchOrders = curry(async (dispatch, params) => {
  // request parameters
  const state = params.state === 'all' ? null : params.state;
  const page = params.page || 1;
  const limit = params.limit || 100;
  const storeReference = params.storeReference || null;
  try {
    const payload = await API.
      get('/yello/orders', {params: {state, page, limit, storeReference}}).
      then(prop('data')).
      then(prop('items'));
    dispatch({type: 'YELLO_ORDERS_FETCH_SUCCESS', payload});
    alert.message('✅ Fetched orders from Yello.', durationSuccessMsg);
    return payload;
  } catch (err) {
    const payload = err.message;
    alert.error(`Failed to fetch orders from Yello. ${payload}`, durationErrorMsg);
    dispatch({type: 'WOOCOMMERCE_ORDERS_FETCH_SUCCESS', payload});
    return payload;
  }
});

export const fetchRemoteConfiguration = curry(async (
  dispatch, params, dataType, errorType
) => {
  try {
    const payload = await API.
      get('/config/search', {params}).
      then(prop('data')).
      then(map(pickAll(['value', 'region'])));

    dispatch({type: dataType, payload});
    return payload;
  } catch (err) {
    const payload = err.message;
    alert.error(payload, durationErrorMsg);
    dispatch({type: errorType, payload});
    return payload;
  }
});

export const deleteOrder = curry(async (dispatch, id) => {
  try {
    const payload = await API.delete(`/yello/orders/${id}`);
    dispatch({type: 'YELLO_ORDER_DELETE_CONFIRMED', payload: id});
    alert.message('✅ Deleted Yello order.', durationSuccessMsg);
    return payload;
  } catch (error) {
    let message;
    const errors = path(['response', 'data', 'errors'], error);
    if (Array.isArray(errors)) {
      message = head(errors);
    } else {
      message = errors.message;
    }
    alert.error(`Cancel Yello order failed. ${message}`, durationErrorMsg);
    return message;
  }
});

export const searchYelloOrders = curry(async (dispatch, state, query) => {
  try {
    if (!(state && query)) {
      console.error('Missing required params');
      return;
    }
    if (query === '') {
      dispatch({type: 'YELLO_SEARCH_REEST', payload: null});
    }
    const payload = await API.post('/yello/orders/search', {state, query}).then(prop('data'));
    dispatch({type: 'YELLO_SEARCH_SUCCESS', payload});
  } catch (err) {
    const message = err.message;
    alert.error(`Search Yello failed. Reason: ${message}`);
  }
});

export const resetYelloSearch = curry((dispatch, event) => {
  dispatch({type: 'YELLO_SEARCH_RESET', payload: null});
});
