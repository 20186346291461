exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".orders-yello__row___16Nsc {\n  margin: 20px 0;\n}", ""]);

// exports
exports.locals = {
	"row": "orders-yello__row___16Nsc"
};