import React from 'react';
import PropTypes from 'prop-types';

import {getFromMetaData} from '../../lib/woocommerce';

import Order from './order';

function Header({index, orders}) {
  const currOrder = orders[index];
  const currDate = getFromMetaData('delivery_date', null, currOrder);
  if (index === 0) {
    return (
      <h2 style={{marginTop: '50px'}}>
        {currDate}
      </h2>
    );
  }

  const prevOrder = orders[index - 1];
  const prevDate = getFromMetaData('delivery_date', null, prevOrder);
  if (currDate !== prevDate) {
    return (
      <h2 style={{marginTop: '50px'}}>
        {currDate}
      </h2>
    );
  }

  return null;
}

Header.propTypes = {
  index: PropTypes.number.isRequired,
  orders: PropTypes.array.isRequired
};

function WooCommerceOrdersList(props) {
  return (
    <div>
      {props.orders.map((order, index) => (
        <div key={order.number}>
          <Header
            index={index}
            orders={props.orders}
          />
          <Order
            key={order.number}
            order={order}
            yelloExport={props.yelloExport}
            onEdit={props.onEdit}
          />
        </div>
      )
      )}
    </div>
  );
}

WooCommerceOrdersList.propTypes = {
  orders: PropTypes.array,
  yelloExport: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired
};

export default WooCommerceOrdersList;
