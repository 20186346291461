import React, { useEffect, useState } from 'react'

import Button from '@jetbrains/ring-ui/components/button/button'
import ButtonGroup from '@jetbrains/ring-ui/components/button-group/button-group';
import LoaderScreen from '@jetbrains/ring-ui/components/loader-screen/loader-screen';
import Input from '@jetbrains/ring-ui/components/input/input';
import {Grid, Row, Col} from '@jetbrains/ring-ui/components/grid/grid'

import Page from '../layout/page'
import Product from '../products/product'

import useProducts from '../../hooks/useProducts'
import useDebounce from '../../hooks/useDebounce'

import styles from './products-page.css'

const Products = ({query}) => {
  const [page, setPage] = useState(1)

  const debouncedQuery = useDebounce(query, 1000)

  const { data: products, error } = useProducts({ page, ...debouncedQuery })


  if (!products && !error) {
    return <LoaderScreen />
  }

  return (
    <div>
        <Row>
          {products && products.map(product => (
            <Col xs={12} sm={6} md={3} lg={3} key={product.id}>
              <Product product={product} />
            </Col>
          ))}
        </Row>
        <Row start='xs' className={styles.buttons}>
          <ButtonGroup>
            <Button
              disabled={page === 1}
              onClick={e => setPage(current => current - 1)}
            >
              Previous Page
            </Button>
            <Button
              disabled={products.length < 20}
              onClick={e => setPage(current => current + 1)}
            >
              Next Page
            </Button>
          </ButtonGroup>
        </Row>
    </div>
  )
}

const ProductsPage = (props) => {
  // manage page state
  const [city, setCity] = useState('')
  const [search, setSearch] = useState('')

  // fetch products
  const query = {
    per_page: 20,
    status: 'publish',
    search: search,
    attribute: 'pa_city',
    attribute_term: city
  }

  return (
    <Page title={'Products'}>
      <Grid className={styles.root}>
        <Row start='xs'>
            <Input
              label='Search'
              value={search}
              onChange={e => setSearch(e.target.value)}
              onClear={e => setSearch('')}
            />
        </Row>
        <Row start='xs'>
          <ButtonGroup>
            <Button active={city == '4418'} onClick={e => setCity('4418')}>
              Melbourne
            </Button>
            <Button active={city == '4419'} onClick={e => setCity('4419')}>
              Sydney
            </Button>
            <Button active={city == '4456'} onClick={e => setCity('4456')}>
              Brisbane
            </Button>
            <Button active={city == '4461'} onClick={e => setCity('4461')}>
              Rest of Australia
            </Button>
          </ButtonGroup>
        </Row>
        <Products query={query} />
      </Grid>
    </Page>
  )
}

export default ProductsPage