import React, { useEffect, useReducer } from 'react'
import {Grid, Row, Col} from '@jetbrains/ring-ui/components/grid/grid'

import API from '../../../lib/api'
import TrelloSelect from '../../shared/trello-select'
import WoocommerceOrdersList from '../../orders/woocommerce-orders-list'
import { removeHash, containsOrderId, extractOrderId } from '../../../lib/woocommerce'
import filters from '../../../lib/filters'

import { editWooCommerceOrder } from '../../../actions/woocommerce'

const R = require('ramda')

const { isNotExported } = filters.woocommerce.orders

const Spinner = (props) => {
  if (props.network === 'active') return <p>Loading...</p>
  if (props.network === 'error') return <p>Problem fetching data. Try again.</p>
  return null
}

const initialState = {}

function reducer (state, action) {
  switch (action.type) {
    case 'WOOCOMMERCE_ORDER_UPDATE_SUCCESS': {
      return state
    }
    case 'WOOCOMMERCE_ORDER_UPDATE_ERROR': {
      return state
    }
    default: {
      throw new Error('Invalid action type')
    }
  }
}

function OrdersWoo (props) {
  const [state, dispatch] = useReducer(reducer, initialState) 

  function handleCards (cards) {
    props.clearOrders()

    const ids = cards
      .map(card => card.name)
      .filter(containsOrderId)
      .map(extractOrderId)
      .map(removeHash)
      .join(',')

    props.fetchOrders(`?status=any&include=${ids}`)
  }

  const orders = props
    .orders
    .woocommerce
    .filter(isNotExported(props.exported))

  return (
    <div>
      <Row>
        <h2>Website</h2>
      </Row>
      <Row>
        <TrelloSelect
          onUpdateBoard={props.clearOrders}
          onUpdateList={props.clearOrders}
          onCards={handleCards}
        />
      </Row>
      <Spinner network={props.network} />
      <WoocommerceOrdersList
        orders={orders}
        yelloExport={props.yelloExport}
        onEdit={editWooCommerceOrder(dispatch)}
      />
    </div>
  )
}

export default OrdersWoo
