import React, { useEffect, useState } from 'react'
import {debounce, throttle} from 'lodash';

import alert from '@jetbrains/ring-ui/components/alert-service/alert-service'
import Toggle, {Size as ToggleSize} from '@jetbrains/ring-ui/components/toggle/toggle';
import Input, {Size as InputSize} from '@jetbrains/ring-ui/components/input/input';

import API from '../../lib/api'
import toImgixUrl from '../../lib/imgix'

import styles from './product.css'

const compose = require('ramda/src/compose')
const head = require('ramda/src/head')
const prop = require('ramda/src/prop')
const propOr = require('ramda/src/propOr')

const featureImage = compose(
  prop('src'),
  head,
  propOr([], 'images')
)

// resize images
const imgixQuery = '?h=400&w=300&fit=crop'

const makeRequest = (current, next, isProcessing, url, msg) => {
  isProcessing(true) // disable UI while request is pending

  API.put(url, { stock_quantity: parseInt(next, 0) })
  .then(({data}) => {
    alert.message(msg, 2000)
    isProcessing(false)
  }).catch(({message}) => {
    alert.error(message, 5000)
    setStockQuantity(current)
    isProcessing(false)
  })
}

const debouncedRequest = debounce(makeRequest, 2000, { leading: false, trailing: true })

const handleChangeStockQuantity = (
  current,
  next,
  setQty,
  isProcessing,
  url,
  msg
) => {
  setQty(next)
  debouncedRequest(current, next, isProcessing, url, msg)
}

const Product = ({product, imageUrl}) => {
  const [stockStatus, setStockStatus] = useState(product.stock_status === 'instock')
  const [manageStock, setManageStock] = useState(product.manage_stock)
  const [stockQuantity, setStockQuantity] = useState(product.stock_quantity ? product.stock_quantity.toString() : '0')
  const [processingStockStatus, setProcessingStockStatus] = useState(false)
  const [processingManageStock, setProcessingManageStock] = useState(false)
  const [processingStockQuantity, setProcessingStockQuantity] = useState(false)

  const url = `/woocommerce/products/${product.id}`

  const handleChangeStockStatus = (bool) => {
    setStockStatus(bool)
    setProcessingStockStatus(true)

    const stock_status = bool ? 'instock' : 'outofstock'
    API.put(url, { stock_status }).then(function () {
      alert.message(`✅ Updated stock status (${product.sku})`, 2000)
      setProcessingStockStatus(false)
    }).catch(function (error) {
      setStockStatus(!stockStatus)
      setProcessingStockStatus(false)
    })

  }

  const handleChangeMangeStock = (bool) => {
    setManageStock(bool)
    setProcessingManageStock(true)

    API
    .put(url, { manage_stock: bool })
    .then(({data}) => {
      alert.message(`✅ Updated manage stock (${product.sku})`, 2000)
      setProcessingManageStock(false)
    })
    .catch(({message}) => {
      alert.error(message, 5000)
      setManageStock(!bool)
      setProcessingManageStock(false)
    })
  }

  return (
    <div className={styles.root}>
      <img src={toImgixUrl('http://placehold.it/300x400', imgixQuery, featureImage(product))} />
      <h3>{product.name}</h3>
      <div className={styles['toggle-container']}>
        <Toggle
          disabled={processingStockStatus}
          size={ToggleSize.Size20}
          checked={stockStatus}
          onChange={() => handleChangeStockStatus(!stockStatus)}
        >
          In Stock?
        </Toggle>
      </div>
      { stockStatus && (
        <div className={styles['toggle-container']}>
          <Toggle
            disabled={processingManageStock}
            size={ToggleSize.Size20}
            checked={manageStock}
            onChange={() => handleChangeMangeStock(!manageStock)}
          >
            Manage stock?
          </Toggle>
        </div>
      )}
      { stockStatus && manageStock && (
        <div className={styles.input}>
          <Input
            disabled={processingStockQuantity}
            label='Stock Quantity'
            size={InputSize.S}
            value={stockQuantity}
            type='number'
            onChange={({target: {value}}) =>
              handleChangeStockQuantity(
                stockQuantity,
                value,
                setStockQuantity,
                setProcessingStockQuantity,
                url,
                `✅ Updated manage stock quantity (${product.sku})`
              )
            }
          />
        </div>
      )}
    </div>
  )
}

export default Product
