const R = require('ramda')

export const memberToOption =
  member => ({ label: member.fullName, key: member.id })

export const optionToMember =
  option => ({ fullName: option.label, id: option.key })

export const boardToOption =
  board => ({ label: board.name, key: board.id })

export const optionToBoard =
  option => ({ name: option.label, id: option.key })

/** toImgixUrl :: string -> string -> string */
/**
 * toImgixUrl
 * @param {string} url trello image url
 * @param {string} params imgix api params
 * @returns {string} imgix image url
 */
export function toImgixUrl (url, params) {
  const sourceUrl = 'https://trello-attachments.s3.amazonaws.com/'
  const imgixUrl = 'http://trelloimages.imgix.net/'
  return url.replace(sourceUrl, imgixUrl) + params
}

export const pluckFromMetaData = key => R.compose(
  R.prop('value'),
  R.find(R.propEq('key', key))
)

export function formatWooCommerceOrders (order) {
  function fullname (obj) {
    let name = ''
    if (obj.first_name.length) {
      name = `${obj.first_name}`
    }
    if (obj.last_name.length) {
      name = `${name} ${obj.last_name}`
    }
    return name
  }

  function fulladdress (obj) {
    let address = ''
    if (obj.company) {
      address = address + obj.company + '\n'
    }
    if (obj.address_1) {
      address = address + obj.address_1 + '\n'
    }
    if (obj.address_2) {
      address = address + obj.address_2 + '\n'
    }
    if (obj.city) {
      address = address + obj.city + '\n'
    }
    if (obj.state) {
      address = address + obj.state + '\n'
    }
    if (obj.postcode) {
      address = address + obj.postcode + '\n'
    }
    return address
  }

  function shippingMethod (array) {
    return R.compose(
      R.prop('method_title'),
      R.find(R.prop('method_title'))
    )(array)
  }

  return {
    orderId: order.id,
    senderFullName: fullname(order.billing),
    recipientFullName: fullname(order.shipping),
    recipientAddress: fulladdress(order.shipping),
    recipientPhone: pluckFromMetaData('shipping_phone', order.meta_data),
    deliveryDate: pluckFromMetaData('delivery_date', order.meta_data),
    deliverTime: shippingMethod(order.shipping_lines)
  }
}

export function toUpperCase (str) {
  return str.toUpperCase()
}

export function titleize (str) {
  return R.compose(
    R.join(''),
    R.append(R.tail(str)),
    toUpperCase,
    R.head
  )(str)
}

export function tokenize (str) {
  return str.toLowerCase()
}
