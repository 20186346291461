import React from 'react'
import Modal from 'react-modal'
import PropTypes from 'prop-types'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
}

Modal.setAppElement('#app-root')

const _Modal = (props) => {
  return (
    <Modal
      shouldCloseOnOverlayClick
      isOpen={props.modalIsOpen}
      onAfterOpen={props.modalAfterOpen}
      onRequestClose={props.modalClose}
      style={customStyles}
      contentLabel='Example Modal'
    >
      <h1 onClick={() => props.modalClose()}>
        Edit Feature - Coming Soon
      </h1>
    </Modal>
  )
}

_Modal.propTypes = {
  modalIsOpen: PropTypes.bool.isRequired,
  modalAfterOpen: PropTypes.func.isRequired,
  modalClose: PropTypes.func.isRequired
}

Modal.setAppElement('#app-root')

export default _Modal
