exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".label-item__address___2xt1- {\n  padding: 20px;\n}\n\n.label-item__address___2xt1-,\n.label-item__message___3eJgP {\n  border: 1px solid #c4c4c4;\n}\n\n.label-item__message___3eJgP {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n}\n\n.label-item__address___2xt1- div,\n.label-item__message___3eJgP div {\n  width: 100%;\n}\n\n.label-item__message___3eJgP textarea {\n  font-size: 1.3em;\n  font-style: italic;\n  font-weight: 300;\n  line-height: 1.5em;\n  padding: 20px;\n}\n\n.label-item__address___2xt1- input {\n  font-size: 1.3em;\n  font-weight: 300;\n}\n\n.label-item__message___3eJgP textarea::placeholder,\n.label-item__address___2xt1- input::placeholder {\n  color: #c4c4c4;\n}\n\n.label-item__footer___2t89T {\n  margin-top: 10px;\n}\n\n.label-item__id___2Cnwe,\n.label-item__skus___1IEUx {\n  margin: 0;\n  padding: 0;\n}\n\n.label-item__id___2Cnwe {\n  text-align: right;\n  font-size: 1.3em;\n  font-weight: 300;\n}\n\n.label-item__skus___1IEUx {\n\n}\n\n.label-item__sku___2V8au {\n  padding: 3px 5px;\n  margin: 0 3px;\n  border: 1px solid #c4c4c4;\n  color: #c4c4c4;\n  font-size: 8px;\n}\n", ""]);

// exports
exports.locals = {
	"address": "label-item__address___2xt1-",
	"message": "label-item__message___3eJgP",
	"footer": "label-item__footer___2t89T",
	"id": "label-item__id___2Cnwe",
	"skus": "label-item__skus___1IEUx",
	"sku": "label-item__sku___2V8au"
};