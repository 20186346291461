import React from 'react';
import PropTypes from 'prop-types';

import YelloOrder from './yello-order';

function YelloOrdersList(props) {
  return (
    <div>
      {props.orders.map(order => (
        <YelloOrder
          key={order._id}
          order={order}
          onEdit={props.onEdit}
          onDelete={props.onDelete}
        />
      ))}
    </div>
  );
}

YelloOrdersList.propTypes = {
  total: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onTrack: PropTypes.func.isRequired,
  orders: PropTypes.array
};

export default YelloOrdersList;
