import React from 'react'
import { Grid, Row, Col } from '@jetbrains/ring-ui/components/grid/grid'
import { Input } from '@jetbrains/ring-ui/components/input/input/'

import styles from './label-item.css'

const LabelItem = (props) => (
  <Row start='xs'>
    <Col xs={12} sm={6} md={6} lg={6}>
      <div className={styles.address}>
        <Input
          borderless
          value={props.address_1}
          onChange={e => props.update(props.id, ['address', 'line_1'], e.target.value)}
        />
        <Input
          borderless
          value={props.address_2}
          placeholder={'Add company name'}
          onChange={e => props.update(props.id, ['address', 'line_2'], e.target.value)}
        />
        <Input
          borderless
          value={props.address_3}
          onChange={e => props.update(props.id, ['address', 'line_3'], e.target.value)}
        />
        <Input
          borderless
          value={props.address_4}
          onChange={e => props.update(props.id, ['address', 'line_4'], e.target.value)}
        />
        <Row className={styles.footer}>
          <Col xs={10} sm={10} md={10} lg={10}>
            <p className={styles.skus}>
              {props.skus.map(sku => (
                <span key={sku} className={styles.sku}>
                  {sku}
                </span>
              ))}
              {/* {`${props.skus}`} */}
            </p>
          </Col>
          <Col xs={2} sm={2} md={2} lg={2}>
            <p className={styles.id}>
              {`${props.id}`}
            </p>
          </Col>
        </Row>
      </div>
    </Col>
    <Col xs={12} sm={6} md={6} lg={6}>
      <div className={styles.message}>
        <Input
          multiline
          borderless
          placeholder={'Add card message'}
          value={props.message}
          onChange={e => props.update(props.id, ['message'], e.target.value)}
        />
      </div>
    </Col>
  </Row>
)

export default LabelItem
