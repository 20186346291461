import React from 'react';

import alert from '@jetbrains/ring-ui/components/alert-service/alert-service';
import Button from '@jetbrains/ring-ui/components/button/button';
import Input from '@jetbrains/ring-ui/components/input/input';
import Select from '@jetbrains/ring-ui/components/select/select';
import Popup from '@jetbrains/ring-ui/components/popup/popup';


const R = require('ramda');

class OrderEditForm extends React.Component {
  constructor(props) {
    super(props);

    const {order} = props;
    const {shipping, billing} = order;

    const statusOptions = [
      {key: 'pending', label: 'Pending', type: 'user'},
      {key: 'processing', label: 'Processing', type: 'user'},
      {key: 'on-hold', label: 'On Hold', type: 'user'},
      {key: 'completed', label: 'Completed', type: 'user'},
      {key: 'cancelled', label: 'Cancelled', type: 'user'},
      {key: 'refunded', label: 'Refunded', type: 'user'},
      {key: 'failed', label: 'Failed', type: 'user'},
      {key: 'trash', label: 'Trash', type: 'user'}
    ];

    this.state = {
      statusOptions,
      isDirty: false,
      id: order.id,
      status: order.status,
      shipping: {
        company: shipping.company,
        first_name: shipping.first_name,
        last_name: shipping.last_name,
        address_1: shipping.address_1,
        address_2: shipping.address_2,
        city: shipping.city,
        state: shipping.state,
        postcode: shipping.postcode
      },
      statusSelected: R.find(R.propEq('key', order.status), statusOptions)
    };
  }

  update = path => e => {
    const nextState = R.compose(
      R.assocPath(path, e.target.value),
      R.assoc('isDirty', true)
    )(this.state);
    this.setState(nextState);
  }

  save = () => {
    this.setState({isDirty: false});
    this.props.onEdit(this.props.order.id, {
      status: R.path(['statusSelected', 'key'], this.state),
      shipping: this.state.shipping
    }).then(result => {
      alert.message(`✅ Updated order #${this.props.order.id}`, 2000);
    }).catch(error => {
      alert.error(error.message, 5000);
      this.setState({isDirty: true});
    });
  }

  reset = () => {
    const {status} = this.props.order;
    const {statusOptions} = this.state;
    this.setState({
      isDirty: false,
      billing: this.props.order.billing,
      shipping: this.props.order.shipping,
      statusSelected: R.find(R.propEq('key', status), statusOptions)
    });
  }

  render() {
    const {fields} = this.props;
    return (
      <form>
        <FormInput
          multiline
          label={'Company'}
          allowed={fields}
          field={'recipient_company'}
          value={this.state.shipping.company}
          onChange={this.update(['shipping', 'company'])}
        />
        <FormInput
          allowed={fields}
          label={'Address 1'}
          field={'recipient_address_1'}
          value={this.state.shipping.address_1}
          onChange={this.update(['shipping', 'address_1'])}
        />
        <FormInput
          allowed={fields}
          label={'Address 2'}
          field={'recipient_address_2'}
          value={this.state.shipping.address_2}
          onChange={this.update(['shipping', 'address_2'])}
        />
        <FormInput
          allowed={fields}
          label={'City'}
          field={'recipient_city'}
          value={this.state.shipping.city}
          onChange={this.update(['shipping', 'city'])}
        />
        <FormInput
          allowed={fields}
          label={'State'}
          field={'recipient_state'}
          value={this.state.shipping.state}
          onChange={this.update(['shipping', 'state'])}
        />
        <FormInput
          allowed={fields}
          label={'Postcode'}
          field={'recipient_postcode'}
          value={this.state.shipping.postcode}
          onChange={this.update(['shipping', 'postcode'])}
        />
        <FormSelect
          allowed={fields}
          label={'Order Status: '}
          field={'order_status'}
          selected={this.state.statusSelected}
          options={this.state.statusOptions}
          onChange={statusSelected => this.setState({isDirty: true, statusSelected})}
        />
        {this.state.isDirty && (
          <div>
            <Button onClick={this.reset}>
              Cancel
            </Button>
            <Button
              primary
              onClick={this.save}
            >
              Save
            </Button>
          </div>
        )}
      </form>
    );
  }
}

function FormInput({label, field, value, allowed, onChange}) {
  if (allowed.includes(field)) {
    return (
      <Input
        multiline
        label={label}
        value={value}
        onChange={onChange}
      />
    );
  }
  return null;
}

function FormSelect({label, field, selected, options, allowed, onChange}) {
  if (allowed.includes(field)) {
    return [
      label,
      <Select
        key="select"
        type={Select.Type.INLINE}
        selected={selected}
        data={options}
        directions={[Popup.PopupProps.Directions.BOTTOM_RIGHT]}
        onChange={onChange}
      />
    ];
  }
  return null;
}

export default OrderEditForm;
