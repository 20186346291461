/* eslint-disable no-magic-numbers */
import React, { useReducer } from 'react'

import append from 'ramda/src/append'
import assoc from 'ramda/src/assoc'
import assocPath from 'ramda/src/assocPath'
import compose from 'ramda/src/compose'
import without from 'ramda/src/without'

import { Grid, Row, Col } from '@jetbrains/ring-ui/components/grid/grid'
import Page from '../../layout/page'

import { fetchOrders as fetchYelloOrders } from '../../../actions/yello'

import {
  fetchOrders as fetchWoocommerceOrders,
  exportToYello
} from '../../../actions/woocommerce'

import OrdersWoo from './orders-woocommerce'
import OrdersYello from './orders-yello'

const initialState = {
  network: 'idle',
  orders: {
    yello: [],
    woocommerce: [],
    exported: []
  },
  _orders: {
    woocommerce: [],
    yello: []
  }
}

// eslint-disable-next-line complexity
function reducer (state, action) {
  switch (action.type) {
    case 'YELLO_ORDERS_FETCH_SUCCESS': {
      return {
        ...state,
        orders: {
          ...state.orders,
          yello: action.payload
        },
        _orders: {
          ...state._orders,
          yello: action.payload
        }
      }
    }
    case 'YELLO_EXPORT_SUCCESS': {
      const exported = append(action.payload, state.orders.exported)
      return assocPath(['orders', 'exported'], exported, state)
    }
    case 'YELLO_EXPORT_ERROR': {
      const exported = without([action.payload], state.orders.exported)
      return assocPath(['orders', 'exported'], exported, state)
    }
    case 'YELLO_ORDERS_FETCH_ERROR': {
      return state
    }
    case 'WOOCOMMERCE_ORDERS_FETCH_REQUEST': {
      return { ...state, network: 'active' }
    }
    case 'WOOCOMMERCE_ORDERS_FETCH_SUCCESS': {
      return compose(
        assoc('network', 'idle'),
        assocPath(['_orders', 'woocommerce'], action.payload),
        assocPath(['orders', 'woocommerce'], action.payload)
      )(state)
    }
    case 'WOOCOMMERCE_ORDERS_FETCH_ERROR': {
      return { ...state, network: action.payload }
    }
    default: {
      console.error(action)
      throw new Error('Invalid action type')
    }
  }
}

function ExportOrdersToTrello () {
  const [state, dispatch] = useReducer(reducer, initialState)

  function clearOrders () {
    dispatch({ type: 'WOOCOMMERCE_ORDERS_FETCH_SUCCESS', payload: [] })
  }

  return (
    <Page title='Export Orders to Yello'>
      <Grid>
        <Row start='xs'>
          <Col xs={12} sm={12} md={6} lg={6}>
            <OrdersWoo
              orders={state.orders}
              _orders={state._orders}
              yelloExport={exportToYello(dispatch)}
              clearOrders={clearOrders}
              fetchOrders={fetchWoocommerceOrders(dispatch)}
              network={state.network}
              exported={state.orders.exported}
            />
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            <OrdersYello
              orders={state.orders}
              wooFetch={fetchWoocommerceOrders(dispatch)}
              yelloFetch={fetchYelloOrders(dispatch)}
            />
          </Col>
        </Row>
      </Grid>
    </Page>
  )
}

export default ExportOrdersToTrello
