exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".trello-select__row___13iSL {\n  margin: 20px 0;\n}", ""]);

// exports
exports.locals = {
	"row": "trello-select__row___13iSL"
};