exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".product__root___3q8Te {\n  border: 1px solid #ddd;\n  margin-top: 25px;\n  display: flex;\n  flex-direction: column;\n  padding-bottom: 16px;\n}\n\n.product__toggle-container___28rh1 {\n  margin-top: 16px;\n  padding: 0 20px;\n}\n\n.product__input___2Vs2C {\n  margin: 16px 0;\n  padding: 0 20px;\n}\n\n.product__root___3q8Te h3 {\n  padding: 20px 20px 0 20px;;\n  margin-top: 0;\n}\n", ""]);

// exports
exports.locals = {
	"root": "product__root___3q8Te",
	"toggle-container": "product__toggle-container___28rh1",
	"input": "product__input___2Vs2C"
};