/* eslint-disable no-magic-numbers */
import React, { useReducer, useEffect } from 'react'
import PropTypes from 'prop-types'

import allPass from 'ramda/src/allPass'
import append from 'ramda/src/append'
import assoc from 'ramda/src/assoc'
import assocPath from 'ramda/src/assocPath'
import compose from 'ramda/src/compose'
import find from 'ramda/src/find'
import filter from 'ramda/src/filter'
import path from 'ramda/src/path'
import pathOr from 'ramda/src/pathOr'
import propOr from 'ramda/src/propOr'
import propEq from 'ramda/src/propEq'

import Button from '@jetbrains/ring-ui/components/button/button'
import Confirm from '@jetbrains/ring-ui/components/confirm/confirm'
import {Grid, Row, Col} from '@jetbrains/ring-ui/components/grid/grid'

import { fetchRemoteConfiguration } from '../../../actions/yello'

import Filter from '../../forms/filter'
import YelloOrdersList from '../../orders/yello-orders-list'

import Search from '../../forms/search'

import styles from './orders-yello.css'
import filters from '../../../lib/filters'
import { deleteOrder } from '../../../actions/yello'

const {
  yello: {
    orders: {
      isSameOrderState,
      isNotInListOfIds
    }
  }
} = filters

const initialState = {
  state: { key: 'new', label: 'New', type: 'new' },
  ordersYello: [],
  pageSize: null,
  currentPage: null,
  pageTotal: null,
  deleteOrderId: null,
  deleted: [],
  storeReferences: [],
  storeOptions: [
    { key: 'all', label: 'All', type: 'user' },
    { key: 'sydney', label: 'Sydney', type: 'user' },
    { key: 'melbourne', label: 'Melbourne', type: 'user' }
  ],
  storeSelected: { key: 'all', label: 'All', type: 'user' },
  optsState: [
    { key: 'all', label: 'All', type: 'user' },
    { key: 'new', label: 'New', type: 'user' },
    { key: 'scheduled', label: 'Scheduled', type: 'user' },
    { key: 'delivering', label: 'Delivering', type: 'user' },
    { key: 'completed', label: 'Completed', type: 'user' },
    { key: 'cancelled', label: 'Cancelled', type: 'user' }
  ],
  confirm: {
    show: false,
    inProgress: false,
    text: 'Do you really wish to proceed?',
    description: 'Yello order will be cancelled.'
  }
}

function reducer (state, action) {
  switch (action.type) {
    case 'SET_OPTS_STATE': {
      return assoc('state', action.payload, state)
    }
    case 'CONFIG_SUCCESS': {
      return assoc('storeReferences', action.payload, state)
    }
    case 'CONFIG_ERROR': {
      console.error(action.payload)
      return state
    }
    case 'YELLO_ORDER_DELETE_REQUEST': {
      return compose(
        assocPath(['confirm', 'show'], true),
        assoc('deleteOrderId', action.payload)
      )(state)
    }
    case 'YELLO_ORDER_DELETE_CONFIRMED': {
      const deleted = append(action.payload, state.deleted)
      return compose(
        assoc('deleted', deleted),
        assocPath(['confirm', 'show'], false),
        assoc('deleteOrderId', null)
      )(state)
    }
    case 'YELLO_ORDER_DELETE_REJECTED': {
      return compose(
        assocPath(['confirm', 'show'], false),
        assoc('deleteOrderId', null)
      )(state)
    }
    case 'LOCATION_SELECTED': {
      return assoc('storeSelected', action.payload, state)
    }
    default: {
      return new Error('Invalid action type.')
    }
  }
}

function OrdersYello (props) {
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    const params = { service: 'yello', key: 'storeReference' }
    fetchRemoteConfiguration(dispatch, params, 'CONFIG_SUCCESS', 'CONFIG_ERROR')
  }, [])

  useEffect(() => {
    const selected = pathOr(null, ['storeSelected', 'key'], state)
    const storeReference = compose(
      propOr(null, 'value'),
      find(propEq('region', selected)),
      path(['storeReferences'])
    )(state)
    props.yelloFetch({ state: 'new', storeReference })
  }, [state.storeSelected])

  function handleStateChange (payload) {
    dispatch({ type: 'SET_OPTS_STATE', payload })
    props.resetSearch()
  }

  function handleDeleteRequest (id) {
    dispatch({ type: 'YELLO_ORDER_DELETE_REQUEST', payload: id })
  }

  function handleDeleteConfirmation () {
    const params = { state: state.state.key, page: state.currentPage }
    deleteOrder(dispatch, state.deleteOrderId, params)
  }

  function handleDeleteRejection () {
    dispatch({ type: 'YELLO_ORDER_DELETE_REJECTED', payload: null })
  }

  const orders = filter(
    allPass([
      isNotInListOfIds(state.deleted),
      isSameOrderState(state.state.key)
    ])
  )(props.orders.yello)

  return (
    <div>
      <h2>{'Yello'}</h2>
      <Row className={styles.row}>
        <Filter
          label={'Store: '}
          options={state.storeOptions}
          selected={state.storeSelected}
          onChange={payload => dispatch({ type: 'LOCATION_SELECTED', payload })}
        />
      </Row>
      <Row className={styles.row}>
        <Filter
          label='Status: '
          options={state.optsState}
          selected={state.state}
          onChange={handleStateChange}
        />
      </Row>
      {/* <Search
        label={'Search...'}
        dispatch={props.yelloSearch(state.state.key)}
        onClear={props.resetSearch}
      /> */}
      <YelloOrdersList
        orders={orders}
        onEdit={null} // TODO: Edit Yello Orders
        onDelete={handleDeleteRequest}
        total={state.pageTotal}
        pageSize={state.pageSize}
        currentPage={state.pageCurrent}
      />
      <Confirm
        show={state.confirm.show}
        text={state.confirm.text}
        description={state.confirm.description}
        inProgress={state.confirm.inProgress}
        confirmLabel='Proceed'
        rejectLabel='Cancel'
        onConfirm={handleDeleteConfirmation}
        onReject={handleDeleteRejection}
      />
    </div>
  )
}

OrdersYello.propTypes = {
  location: PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired
  }).isRequired,
  orders: PropTypes.shape({
    yello: PropTypes.arrayOf(PropTypes.shape({
      orderReference: PropTypes.string.isRequired
    })),
    woocommerce: PropTypes.array
  }),
  wooFetch: PropTypes.func.isRequired,
  yelloFetch: PropTypes.func.isRequired,
  yelloSearch: PropTypes.func.isRequired,
  resetSearch: PropTypes.func.isRequired
}

export default OrdersYello
